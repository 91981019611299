import React, { useState } from "react";
import Product from "./product";
import useSessionStorage from "shared/hooks/session-storage"
import OrderSwitcher from './filters/order'

const List = ({ layout, config, results, order, changeOrder }) => {

	const [ visibleProducts, setVisibleProducts ] = useSessionStorage( config.path, config.loadMore.active ? parseInt(config.loadMore.perPage) : 0 );

	const renderProducts = () => {
		if( config.loadMore.active ) {
			return results.products.slice(0, visibleProducts).map(product => (
				<Product
				key={product.id}
				product={product}
				cdnPath={config.cdnPath}
				path={config.path}
				promoted={config.allowPromoted}
				/>
			));
		}

		return results.products.map(product => (
			<Product
			key={product.id}
			product={product}
			cdnPath={config.cdnPath}
			path={config.path}
			promoted={config.allowPromoted}
			/>
		));
	};

	return (
		<div>
				
			<div className="grid-x grid-padding-x">
				<div className="small-6 cell">
					<h4>{results.products.length} activities found in {config.title}</h4>
				</div>
				<div className="small-6 cell show-for-large">
					<div className="align-content horizontal right">
						<OrderSwitcher
							order={order}
							changeOrder={changeOrder} 
						/>
					</div>
				</div>
			</div>
					
			<div className={`grid-x grid-padding-x small-up-1 medium-up-2 large-up-4 product-grid ${layout}-view`}>
				{renderProducts()}
			</div>

			{config.loadMore.active && visibleProducts < results.products.length && (
				<div className="grid-x grid-padding-x align-center">
					<div className="cell medium-6 text-center">
						<button
							onClick={() => {
								setVisibleProducts( parseInt( visibleProducts + config.loadMore.perPage ) );
							}}
							type="button"
							className="load-more"
						>
							Load more
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default List;
